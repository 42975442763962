import { graphql, Link } from 'gatsby';
import { GatsbyImage } from 'gatsby-plugin-image';
import { useIntl } from 'gatsby-plugin-intl';
import React from 'react';
import Layout from '../components/Layout';
import SEO from '../components/SEO';

const ArchivePage = ({ location, data: { allContentfulChapter } }) => {
  const intl = useIntl();
  const chapters = allContentfulChapter.edges;

  return (
    <Layout>
      <SEO pathname={location.pathname} title={intl.formatMessage({ id: 'archive-page-title' })} />

      <div className="relative w-11/12 max-w-screen-lg mx-auto pt-24">
        <h1 className="font-bold text-2xl text-center mb-24">{intl.formatMessage({ id: 'archive-page-title' })}</h1>

        {chapters.map(({ node: chapter }) => (
          <section className="grid grid-cols-2 w-full md:grid-cols-3 lg:grid-cols-4 gap-10 mb-10" key={chapter.id}>
            <header className="h-96 bg-red flex justify-center items-center text-center">
              <h2>{chapter.title}</h2>
            </header>

            {chapter.pages.map((page) => (
              <article className="h-96 border-2 border-white" key={page.id}>
                <Link to={`/${intl.locale}/${page.chapter[0].slug}/${page.slug}`}>
                  <GatsbyImage image={page.image.gatsbyImageData} alt="" className="w-full h-full" />
                </Link>
              </article>
            ))}
          </section>
        ))}
      </div>
    </Layout>
  );
};

export const query = graphql`
  query ArchiveQuery($locale: String!) {
    allContentfulChapter(sort: { fields: createdAt, order: ASC }, filter: { node_locale: { eq: $locale } }) {
      edges {
        node {
          slug
          node_locale
          title
          pages {
            slug
            title
            image {
              gatsbyImageData(layout: CONSTRAINED, width: 400, placeholder: DOMINANT_COLOR, quality: 100)
            }
            chapter {
              slug
            }
          }
        }
      }
    }
  }
`;

export default ArchivePage;
